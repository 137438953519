import { Datagrid, List, SearchInput, TextField, UrlField } from 'react-admin';

export const ProtocolList = () => (
	<List filters={[<SearchInput source="q" alwaysOn />]}>
		<Datagrid rowClick="edit" isRowSelectable={() => false}>
			<TextField source="id" />
			<TextField source="chain" />
			<TextField source="name" />
			<TextField source="platform_token_chain" />
			<TextField source="platform_token_id" reference="platform_tokens" />
			<TextField source="platform_token_symbol" />
			<UrlField source="site_url" />
		</Datagrid>
	</List>
);
