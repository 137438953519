import {
	AutocompleteArrayInput,
	AutocompleteInput,
	BooleanInput,
	Edit,
	ReferenceArrayInput,
	ReferenceInput,
	SelectInput,
	SimpleForm,
	TextInput,
} from 'react-admin';

export const SourceEdit = () => (
	<Edit>
		<SimpleForm>
			<TextInput source="id" />
			<ReferenceInput source="accountId" reference="feed-accounts" required>
				<AutocompleteInput
					label="Feed Account"
					optionText={c => `id: ${c.id}, name: ${c.name}, type: ${c.type}`}
					fullWidth
				/>
			</ReferenceInput>
			<SelectInput
				source="type"
				choices={[
					{ id: 'twitter', name: 'twitter' },
					{ id: 'mirror', name: 'mirror' },
					{ id: 'discord', name: 'discord' },
					{ id: 'telegram', name: 'telegram' },
					{ id: 'medium', name: 'medium' },
				]}
			/>
			<BooleanInput source="enabled" />
			<TextInput source="guildName" />
			<TextInput source="channelName" />
			<TextInput source="link" />
			<ReferenceInput source="projectId" reference="projects">
				<AutocompleteInput source="id" label="Project" optionText={c => `${c.id} - ${c.name}`} fullWidth />
			</ReferenceInput>
			<ReferenceArrayInput source="tags" reference="tags">
				<AutocompleteArrayInput source="tags" label="Tag" optionText={c => `${c.id} - ${c.name}`} fullWidth />
			</ReferenceArrayInput>
		</SimpleForm>
	</Edit>
);
