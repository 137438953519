import { fetchUtils } from 'react-admin';
import s from 'query-string';

const stringify = s.stringify;

export const apiUrl = 'https://mv.ylide.io/admin';
// process.env.NODE_ENV === 'development' ? 'http://localhost:8271/admin' : 'https://mv.ylide.io/admin';
const httpClient = fetchUtils.fetchJson;

export const dataProvider = (code: string) => ({
	getList: (resource: string, params: any) => {
		const { page, perPage } = params.pagination;
		const { field, order } = params.sort;
		const query = {
			sort: JSON.stringify([field, order]),
			range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
			filter: JSON.stringify(params.filter),
			code,
		};
		const url = `${apiUrl}/${resource}?${stringify(query)}`;

		return httpClient(url).then(({ headers, json }) => {
			return {
				data: json,
				total: parseInt(headers.get('content-range')?.split('/')?.pop() || '0', 10),
			};
		});
	},

	getOne: (resource: string, params: any) => {
		const query = {
			code,
		};
		const url = `${apiUrl}/${resource}/${params.id}?${stringify(query)}`;
		return httpClient(url).then(({ json }) => ({
			data: json,
		}));
	},

	getMany: (resource: string, params: any) => {
		const query = {
			filter: JSON.stringify({ ids: params.ids }),
			code,
		};
		const url = `${apiUrl}/${resource}?${stringify(query)}`;
		return httpClient(url).then(({ json }) => ({ data: json }));
	},

	getManyReference: (resource: string, params: any) => {
		const { page, perPage } = params.pagination;
		const { field, order } = params.sort;
		const query = {
			sort: JSON.stringify([field, order]),
			range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
			code,
			filter: JSON.stringify({
				...params.filter,
				[params.target]: params.id,
			}),
		};
		const url = `${apiUrl}/${resource}?${stringify(query)}`;

		return httpClient(url).then(({ headers, json }) => ({
			data: json,
			total: parseInt(headers.get('content-range')?.split('/')?.pop() || '0', 10),
		}));
	},

	create: (resource: string, params: any) => {
		const query = {
			code,
		};
		const url = `${apiUrl}/${resource}?${stringify(query)}`;
		return httpClient(url, {
			method: 'POST',
			body: JSON.stringify(params.data),
		}).then(({ json }) => ({
			data: { ...params.data, id: json.id },
		}));
	},

	update: (resource: string, params: any) => {
		const query = {
			code,
		};
		const url = `${apiUrl}/${resource}/${params.id}?${stringify(query)}`;
		return httpClient(url, {
			method: 'PUT',
			body: JSON.stringify(params.data),
		}).then(({ json }) => ({ data: json }));
	},

	updateMany: (resource: string, params: any) => {
		const query = {
			filter: JSON.stringify({ id: params.ids }),
			code,
		};
		return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
			method: 'PUT',
			body: JSON.stringify(params.data),
		}).then(({ json }) => ({ data: json }));
	},

	delete: (resource: string, params: any) => {
		const query = {
			code,
		};
		return httpClient(`${apiUrl}/${resource}/${params.id}?${stringify(query)}`, {
			method: 'DELETE',
		}).then(({ json }) => ({ data: json }));
	},

	deleteMany: (resource: string, params: any) => {
		const query = {
			filter: JSON.stringify({ id: params.ids }),
			code,
		};
		return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
			method: 'DELETE',
			body: JSON.stringify(params.data),
		}).then(({ json }) => ({ data: json }));
	},
});
