import { Datagrid, List, SearchInput, TextField } from 'react-admin';

export const ManagerList = () => (
	<List filters={[<SearchInput source="q" alwaysOn />]}>
		<Datagrid rowClick="edit">
			<TextField source="feedId" />
			<TextField source="address" />
			<TextField source="title" />
			<TextField source="rank" />
			<TextField source="emoji" />
			<TextField source="role" />
		</Datagrid>
	</List>
);
