import {
	BooleanInput,
	Datagrid,
	List,
	ReferenceField,
	SearchInput,
	TextField,
	UrlField,
	WrapperField,
} from 'react-admin';

export const DebankEntityList = () => (
	<List
		filters={[<SearchInput source="q" alwaysOn />, <BooleanInput source="withoutProject" alwaysOn />]}
		filterDefaultValues={{ withoutProject: false }}
	>
		<Datagrid rowClick="edit">
			<TextField source="id" />
			<ReferenceField source="projectId" reference="projects" sortable={false}>
				<TextField source="name" />
			</ReferenceField>
			<WrapperField label="Links" sortable={false}>
				<UrlField source="twitter" target="blank" style={{ display: 'block' }} />
				<UrlField source="discord" target="blank" style={{ display: 'block' }} />
				<UrlField source="telegram" target="blank" style={{ display: 'block' }} />
				<UrlField source="mirror" target="blank" style={{ display: 'block' }} />
				<UrlField source="medium" target="blank" style={{ display: 'block' }} />
				<UrlField source="site" target="blank" style={{ display: 'block' }} />
			</WrapperField>
			<TextField source="gecko_id" sortable={false} />
			<TextField source="llama_id" sortable={false} />
			<TextField source="outdated_links" sortable={false} />
		</Datagrid>
	</List>
);
