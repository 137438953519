import { Hub } from './Hub';
import { MV } from './MV';

function App() {
	const url = new URL(window.location.href).pathname;

	if (url === '/socialhub') {
		return <Hub />;
	}

	return <MV />;
}

export default App;
