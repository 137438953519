import { useState } from 'react';
import { fetchUtils } from 'react-admin';
import { apiUrl } from '../dataProvider';
import qs from 'query-string';

const stringify = qs.stringify;
const httpClient = fetchUtils.fetchJson;

export const AuthLinksHelper = () => {
	const code = new URL(window.location.href).searchParams.get('code');

	const [campaign, setCampaign] = useState('');
	const [addresses, setAddresses] = useState('');
	const [links, setLinks] = useState('');

	const [initLoading, setInitLoading] = useState(false);
	const [generateLoading, setGenerateLoading] = useState(false);

	const initAccounts = async () => {
		setInitLoading(true);
		const rawAddresses = addresses
			.split('\n')
			.map(t => t.split(' ')[0].trim().toLowerCase())
			.filter(t => t);
		const results: Record<string, string> = {};
		// fetch here: /init-users
		for (const address of rawAddresses) {
			const addresses = [address];
			results[address] = 'initializing...';
			setAddresses(rawAddresses.map(a => (results[a] ? `${a} - ${results[a]}` : a)).join('\n'));
			const response = await httpClient(`${apiUrl}/init-users?${stringify({ code })}`, {
				method: 'POST',
				body: JSON.stringify({ addresses }),
			});
			const data: { newAccounts: string[]; existingAccounts: string[] } = response.json;
			results[address] = data.newAccounts.length ? 'new' : 'existing';
			setAddresses(rawAddresses.map(a => (results[a] ? `${a} - ${results[a]}` : a)).join('\n'));
		}
		setInitLoading(false);
	};

	const generateAuthLinks = async () => {
		setGenerateLoading(true);
		const rawAddresses = addresses
			.split('\n')
			.map(t => t.split(' ')[0].trim().toLowerCase())
			.filter(t => t);
		// fetch here: /create-links
		const response = await httpClient(`${apiUrl}/create-links?${stringify({ code })}`, {
			method: 'POST',
			body: JSON.stringify({
				addresses: rawAddresses,
				campaign,
			}),
		});
		const data: Record<string, string> = response.json;
		setLinks(rawAddresses.map(a => `${a} -> ${data[a]}`).join('\n'));
		setGenerateLoading(false);
	};

	return (
		<div>
			<h1>Auth links generator</h1>
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
						<input
							style={{
								marginBottom: '10px',
							}}
							value={campaign}
							onChange={e => setCampaign(e.target.value)}
							placeholder="Promo campaign name"
						/>
						<textarea
							disabled={initLoading || generateLoading}
							style={{ minHeight: 300, fontFamily: 'monospace' }}
							value={addresses}
							onChange={e => setAddresses(e.target.value)}
							placeholder="Addresses"
						/>
					</div>
					<div
						style={{
							flexBasis: 300,
							marginLeft: 40,
							marginRight: 40,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'stretch',
						}}
					>
						<button
							onClick={e => {
								e.preventDefault();
								initAccounts();
							}}
							style={{ marginBottom: 20, height: 40 }}
							disabled={initLoading}
						>
							{initLoading ? 'Loading...' : 'Initialize addresses'}
						</button>
						<button
							onClick={e => {
								e.preventDefault();
								generateAuthLinks();
							}}
							style={{ marginBottom: 20, height: 40 }}
							disabled={generateLoading}
						>
							{generateLoading ? 'Loading...' : 'Generate auth links'}
						</button>
					</div>
				</div>
				<div style={{ marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
					<textarea
						style={{ minHeight: 300, marginRight: 40, fontFamily: 'monospace' }}
						value={links}
						placeholder="Links will be here"
					/>
				</div>
			</div>
		</div>
	);
};
