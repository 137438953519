import { Create, SimpleForm, TextInput } from 'react-admin';

export const ManagerCreate = () => (
	<Create>
		<SimpleForm>
			<TextInput source="feedId" fullWidth required />
			<TextInput source="address" fullWidth required />
			<TextInput source="password" fullWidth />
			<TextInput source="title" fullWidth />
			<TextInput source="rank" fullWidth defaultValue={'Mod'} />
			<TextInput source="emoji" fullWidth />
			<TextInput source="role" fullWidth defaultValue={'admin'} />
		</SimpleForm>
	</Create>
);
