import { Edit, SimpleForm, TextField, TextInput } from 'react-admin';

export const TagEdit = () => (
	<Edit>
		<SimpleForm>
			<TextField source="id" />
			<TextInput source="name" />
		</SimpleForm>
	</Edit>
);
