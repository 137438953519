import { useEffect, useState } from 'react';
import { Admin, CustomRoutes, Resource } from 'react-admin';
import { Route } from 'react-router-dom';
import { YlideLayout } from './Layout';
import { CoverageList } from './coverage/list';
import { Dashboard } from './dashboard/Dashboard';
import { apiUrl, dataProvider } from './dataProvider';
import { DebankEntityCreate } from './debank-entity/create';
import { DebankEntityEdit } from './debank-entity/edit';
import { DebankEntityList } from './debank-entity/list';
import { ProjectCreate } from './project-entity/create';
import { ProjectEdit } from './project-entity/edit';
import { ProjectList } from './project-entity/list';
import { ProtocolList } from './protocol/list';
import { SourceCreate } from './source-entity/create';
import { SourceEdit } from './source-entity/edit';
import { SourceList } from './source-entity/list';
import { TagCreate } from './tag/create';
import { TagEdit } from './tag/edit';
import { TagList } from './tag/list';
import { TokenList } from './token/list';
import { AuthLinksHelper } from './auth-link/helper';

export const MV = () => {
	const code = new URL(window.location.href).searchParams.get('code');
	const [authorized, setAuthorized] = useState<'true' | 'false' | 'error' | 'null'>('null');
	useEffect(() => {
		fetch(`${apiUrl}/ping?code=${code}`)
			.then(r => {
				if (r.status === 200) {
					setAuthorized('true');
				} else if (r.status === 401) {
					setAuthorized('false');
				}
			})
			.catch(e => {
				console.log(e);
				setAuthorized('error');
			});
	}, [code]);

	if (authorized === 'false') {
		return <h1>Unauthorized</h1>;
	}
	if (authorized === 'error') {
		return <h1>Server error</h1>;
	}
	if (authorized === 'null') {
		return <></>;
	}
	return (
		<Admin dataProvider={dataProvider(code || '')} layout={YlideLayout}>
			<Resource name="projects" list={ProjectList} edit={ProjectEdit} create={ProjectCreate} />
			<Resource
				name="debank-entities"
				list={DebankEntityList}
				create={DebankEntityCreate}
				edit={DebankEntityEdit}
			/>
			<Resource name="sources" list={SourceList} create={SourceCreate} edit={SourceEdit} />
			<Resource name="coverage" list={CoverageList} />
			<Resource name="tokens" list={TokenList} />
			<Resource name="protocols" list={ProtocolList} />
			{/* <Resource name="auth-links" list={AuthLinkList} /> */}
			<Resource name="tags" list={TagList} edit={TagEdit} create={TagCreate} />
			<CustomRoutes>
				<Route path="/coverage-dashboard" element={<Dashboard />} />
				<Route path="/auth-links" element={<AuthLinksHelper />} />
			</CustomRoutes>
		</Admin>
	);
};
