import { Datagrid, List, ReferenceField, SearchInput, TextField } from 'react-admin';

export const TokenList = () => (
	<List filters={[<SearchInput source="q" alwaysOn />]}>
		<Datagrid isRowSelectable={() => false} rowClick="edit">
			<TextField source="id" />
			<TextField source="symbol" />
			<TextField source="name" />
			<ReferenceField source="protocol_id" reference="protocols" />
		</Datagrid>
	</List>
);
