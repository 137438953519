import {
	AutocompleteArrayInput,
	BooleanInput,
	Edit,
	ReferenceArrayInput,
	SimpleForm,
	TextField,
	TextInput,
} from 'react-admin';

const getLinks = (c: any) => [c.twitter, c.discord, c.telegram, c.mirror, c.medium].filter(c => c).join(', ');

export const ProjectEdit = () => (
	<Edit>
		<SimpleForm>
			<TextField source="id" />
			<TextInput source="name" />
			<TextInput source="site" />
			<BooleanInput source="isdead" />
			<ReferenceArrayInput source="debankEntityIds" reference="debank-entities">
				<AutocompleteArrayInput
					source="id"
					label="Debank Entity"
					optionText={c => `${c.id} - ${getLinks(c)}`}
					fullWidth
				/>
			</ReferenceArrayInput>
			<ReferenceArrayInput source="sourceIds" reference="sources">
				<AutocompleteArrayInput source="id" label="Source" optionText={c => `${c.id} - ${c.link}`} fullWidth />
			</ReferenceArrayInput>
		</SimpleForm>
	</Edit>
);
