import { fetchUtils } from 'react-admin';
import useSWR from 'swr';
import { apiUrl } from '../dataProvider';

import s from 'query-string';
import { CoverageInfo, Ratio } from '../types';

const stringify = s.stringify;

const httpClient = fetchUtils.fetchJson;

export const Dashboard = () => {
	const code = new URL(window.location.href).searchParams.get('code');

	const r = useSWR<{
		coverages: [
			string,
			{
				tokens: CoverageInfo;
				protocols: CoverageInfo;
				transactions: Ratio;
				totalCoverage: number;
				status: string;
				referrer?: string;
			},
		][];
		totalSubscriptions: number;
		activeTrials: number;
		totalCharges: number;
	}>('coverages', async () => {
		const response = await httpClient(`${apiUrl}/coverages?${stringify({ code })}`).then(({ json }) => json);
		let totalSubscriptions = 0;
		let totalCharges = 0;
		let activeTrials = 0;
		const result = (
			response.map(([a, c]: [string, any]) => {
				const total = c.tokens.usdTotal + c.protocols.usdTotal;
				const covered = c.tokens.usdCovered + c.protocols.usdCovered;
				c.totalCoverage = total > 0 ? Math.floor((covered * 100) / total) : 0;
				c.status =
					c.trial === 'active'
						? 'Trial active'
						: c.subscription
						? `Monthly until ${new Date(c.subscription.next_charge * 1000).toDateString()}`
						: c.charge
						? `Paid year until ${new Date(c.charge.endOfPeriod * 1000).toDateString()}`
						: '';
				if (c.subscription) {
					totalSubscriptions++;
				}
				if (c.charge) {
					totalCharges++;
				}
				if (c.trial === 'active') {
					activeTrials++;
				}
				return [a, c];
			}) as [
				string,
				{
					tokens: CoverageInfo;
					protocols: CoverageInfo;
					transactions: Ratio;
					totalCoverage: number;
					status: string;
					activeTrials: number;
					referrer?: string;
				},
			][]
		)
			.filter(([a, c]) => c.tokens.usdTotal > 0 || c.protocols.usdTotal > 0)
			.sort(([a1, c1], [a2, c2]) => c1.totalCoverage - c2.totalCoverage);

		return { coverages: result, totalCharges, totalSubscriptions, activeTrials };
	});
	return (
		<div>
			{r.isLoading && 'Loading...'}
			{r.data && (
				<>
					<h3>
						Active trials: {r.data.activeTrials}. Subscriptions: {r.data.totalSubscriptions}. Charges:{' '}
						{r.data.totalCharges}
					</h3>
					<table>
						<thead>
							<tr>
								<th>Address</th>
								<th>Referrer</th>
								<th>Status</th>
								<th>Protocols USD ratio</th>
								<th>Tokens USD ratio</th>
								<th>Total USD ratio</th>
								<th>Missing Tokens / Protocols</th>
							</tr>
						</thead>
						<tbody>
							{r.data.coverages.map(([address, coverage]) => {
								return (
									<tr key={address}>
										<td>{address}</td>
										<td>{coverage.referrer || '-'}</td>
										<td>{coverage.status || '-'}</td>
										<td>{coverage.protocols.ratioUsd}</td>
										<td>{coverage.tokens.ratioUsd}</td>
										<td>{coverage.totalCoverage}</td>
										<td>
											<ul>
												{coverage.protocols.items
													.filter(p => p.missing)
													.map((p, i) => (
														<li key={`protocol-${i}`}>
															{p.tokenId} - {p.name} - {p.symbol}
														</li>
													))}
											</ul>
											<ul>
												{coverage.tokens.items
													.filter(p => p.missing)
													.map((p, i) => (
														<li key={`token-${i}`}>
															{p.tokenId} - {p.name} - {p.symbol}
														</li>
													))}
											</ul>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</>
			)}
		</div>
	);
};
