import { Create, SimpleForm, TextInput } from 'react-admin';

export const ProjectCreate = () => (
	<Create>
		<SimpleForm>
			<TextInput source="name" />
			<TextInput source="site" />
		</SimpleForm>
	</Create>
);
