import { Admin, Resource } from 'react-admin';
import { ManagerCreate } from './hub/manager/create';
import { ManagerEdit } from './hub/manager/edit';
import { ManagerList } from './hub/manager/list';
import { hubDataProvider } from './hubDataProvider';

export const Hub = () => {
	const code = new URL(window.location.href).searchParams.get('code');

	if (!code) {
		return <div>Unauthorized</div>;
	}

	return (
		<Admin
			dataProvider={hubDataProvider(
				new Headers({
					'Content-Type': 'application/json',
					'X-Code': code,
				}),
			)}
		>
			<Resource name="manager" list={ManagerList} edit={ManagerEdit} create={ManagerCreate} />
		</Admin>
	);
};
