import { Create, SimpleForm, TextInput } from 'react-admin';

export const DebankEntityCreate = () => (
	<Create>
		<SimpleForm>
			<TextInput source="id" fullWidth />
			<TextInput source="site" fullWidth />
			<TextInput source="twitter" fullWidth />
			<TextInput source="discord" fullWidth />
			<TextInput source="telegram" fullWidth />
			<TextInput source="mirror" fullWidth />
			<TextInput source="medium" fullWidth />
		</SimpleForm>
	</Create>
);
