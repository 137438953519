import {
	BooleanField,
	BooleanInput,
	ChipField,
	Datagrid,
	List,
	ReferenceArrayField,
	ReferenceField,
	SearchInput,
	SingleFieldList,
	TextField,
	UrlField,
} from 'react-admin';

export const SourceList = () => (
	<List filters={[<SearchInput source="q" alwaysOn />, <BooleanInput source="withoutProject" alwaysOn />]}>
		<Datagrid rowClick="edit">
			<TextField source="id" />
			<UrlField source="link" target="blank" />
			<ReferenceField source="projectId" reference="projects" sortable={false}>
				<ChipField source="name" />
			</ReferenceField>
			<ReferenceArrayField source="tags" reference="tags" sortable={false}>
				<SingleFieldList>
					<ChipField source="name" />
				</SingleFieldList>
			</ReferenceArrayField>
			<BooleanField source="enabled" />
			<TextField source="channelName" />
			<BooleanField source="isInList" />
			<TextField source="type" />
			<TextField source="guildName" />
		</Datagrid>
	</List>
);
