import { AutocompleteInput, Edit, ReferenceInput, SimpleForm, TextField, TextInput } from 'react-admin';

export const DebankEntityEdit = () => (
	<Edit>
		<SimpleForm>
			<TextField source="id" fullWidth style={{ fontWeight: 'bold' }} />
			<TextInput source="site" fullWidth />
			<TextInput source="twitter" fullWidth />
			<TextInput source="discord" fullWidth />
			<TextInput source="telegram" fullWidth />
			<TextInput source="mirror" fullWidth />
			<TextInput source="medium" fullWidth />
			<ReferenceInput source="projectId" reference="projects">
				<AutocompleteInput label="Project" optionText={c => `id: ${c.id}, name: ${c.name}`} fullWidth />
			</ReferenceInput>
		</SimpleForm>
	</Edit>
);
