import { Layout, Menu } from 'react-admin';

export const YlideLayout = (props: any) => (
	<Layout
		{...props}
		menu={() => (
			<Menu>
				<Menu.ResourceItem name="projects" />
				<Menu.ResourceItem name="debank-entities" />
				<Menu.ResourceItem name="sources" />
				<Menu.ResourceItem name="tokens" />
				<Menu.ResourceItem name="protocols" />
				<Menu.ResourceItem name="tags" />
				<Menu.ResourceItem name="coverage" />
				<Menu.Item primaryText="Coverage dashboard" to="/coverage-dashboard" />
				<Menu.Item primaryText="Auth links" to="/auth-links" />
			</Menu>
		)}
	/>
);
