import {
	ArrayField,
	BooleanInput,
	ChipField,
	Datagrid,
	List,
	ReferenceField,
	SearchInput,
	SingleFieldList,
	TextField,
	useRecordContext,
} from 'react-admin';

const UrlArray = ({ source }: { source: string }) => {
	const record = useRecordContext();
	if (record) {
		return (
			<ul>
				{record[source].map((link: string, index: number) => {
					return (
						<li key={`link-${index}`}>
							<a href={link} target="_blank" rel="noopener noreferrer">
								{link}
							</a>
						</li>
					);
				})}
			</ul>
		);
	}
	return <div></div>;
};

export const CoverageList = () => (
	<List
		filters={[<SearchInput source="q" alwaysOn />, <BooleanInput source="withoutTransactions" alwaysOn />]}
		filterDefaultValues={{ withoutTransactions: true }}
	>
		<Datagrid isRowSelectable={() => false} rowClick="edit">
			<TextField source="id" />
			<ReferenceField source="debankEntityId" reference="debank-entities" />
			<ReferenceField source="projectId" reference="projects" />
			<ReferenceField source="sourceId" reference="sources" />
			<ReferenceField source="tokenId" reference="tokens">
				<ChipField source="id" />
				<ChipField source="name" />
				<ChipField source="symbol" />
			</ReferenceField>
			<TextField source="protocolId" reference="protocols" />
			<UrlArray source="links" />
			<ArrayField source="reasonsData">
				<SingleFieldList>
					<ChipField source="type" />
				</SingleFieldList>
			</ArrayField>
		</Datagrid>
	</List>
);
