import { Edit, SimpleForm, TextField, TextInput } from 'react-admin';

export const ManagerEdit = () => (
	<Edit>
		<SimpleForm>
			<TextField source="id" fullWidth />
			<TextField source="createTimestamp" fullWidth />
			<TextInput source="password" />
			<TextInput source="feedId" fullWidth />
			<TextInput source="address" fullWidth />
			<TextInput source="title" fullWidth />
			<TextInput source="rank" fullWidth />
			<TextInput source="emoji" fullWidth />
			<TextInput source="role" fullWidth />
		</SimpleForm>
	</Edit>
);
