import {
	BooleanField,
	BooleanInput,
	ChipField,
	Datagrid,
	List,
	ReferenceArrayField,
	SearchInput,
	SingleFieldList,
	TextField,
	UrlField,
} from 'react-admin';

export const ProjectList = () => (
	<List
		filters={[
			<SearchInput source="q" alwaysOn />,
			<BooleanInput source="withoutDebankEntities" alwaysOn />,
			<BooleanInput source="withoutSources" alwaysOn />,
			<BooleanInput source="excludeDead" alwaysOn />,
		]}
		filterDefaultValues={{
			excludeDead: true,
			withoutDebankEntities: false,
			withoutSources: false,
		}}
	>
		<Datagrid rowClick="edit">
			<TextField source="id" />
			<TextField source="name" />
			<BooleanField source="isdead" />
			<UrlField source="site" />
			<ReferenceArrayField source="debankEntityIds" reference="debank-entities" sortable={false} />
			<ReferenceArrayField source="sourceIds" sortable={false} reference="sources">
				<SingleFieldList>
					<ChipField source="link" />
				</SingleFieldList>
			</ReferenceArrayField>
		</Datagrid>
	</List>
);
