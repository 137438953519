import {
	AutocompleteInput,
	BooleanInput,
	Create,
	ReferenceInput,
	SelectInput,
	SimpleForm,
	TextInput,
} from 'react-admin';

export const SourceCreate = () => (
	<Create>
		<SimpleForm>
			<SelectInput
				source="type"
				choices={[
					{ id: 'twitter', name: 'twitter' },
					{ id: 'mirror', name: 'mirror' },
					{ id: 'discord', name: 'discord' },
					{ id: 'telegram', name: 'telegram' },
					{ id: 'medium', name: 'medium' },
				]}
				required
			/>
			<TextInput source="link" required />
			<BooleanInput source="enabled" defaultValue={true} />
			<ReferenceInput source="accountId" reference="feed-accounts" required>
				<AutocompleteInput
					label="Feed Account"
					optionText={c => `id: ${c.id}, name: ${c.name}, type: ${c.type}`}
					fullWidth
				/>
			</ReferenceInput>
			<TextInput source="guildName" />
			<TextInput source="channelName" />
		</SimpleForm>
	</Create>
);
